<template>
  <div>
    <div class="info">
      <div class="top">
        <span class="title">《热血大乱斗》账号注销</span>
      </div>
      <div class="cont">
        <h4 class="commonTitle">在您提交注销申请生效前，请先确认以下信息，并在页面底部勾选确认，以保证您的帐号、财产安全。</h4>

        <h4>游戏帐号注销条件:</h4>

        <h4>1.帐号处于安全状态</h4>
        <span class="common">帐号处于正常使用状态，无被盗风险</span>

        <h4>2.游戏帐号下虚拟财产已结清或得到妥善处理</h4>
        <span class="common">请您确认游戏帐号下虚拟财产 (包括但不限于已消耗及未消耗的虚拟货币、虚拟物品、游戏虚拟权益等)已结清或已得到妥善处理。若未处理，视为您自愿放弃该虚拟财产，注销后将作废且无法恢复。</span>

        <h4>3游戏帐号下无未完成的订单</h4>
        <span class="common">请您确认游戏帐号下无未完成的订单，包括但不限于充值点券、钻石、金币、购买游戏人物、皮肤、礼包、虚拟道具、周边商品等。 请您确认苹果账户订阅已经取消，注销账号不会自动取消苹果订阅扣费，查看和管理订阅请点击这里
        (https://apps.apple.com/account/subscriptions)</span>

        <h4>4.游戏号无任何纠纷</h4>
        <span class="common">包括但不限于投诉/被投诉纠纷、举报/被举报纠纷、仲裁、诉讼等。</span>

        <h4>游戏帐号注销规则:</h4>

        <span class="common">1.冷静期为30天
          该帐号注销有30天的冷静期，您可以在发起注销后的30天内进行联系客服撤销注销申请。除法律规定和本条款约定的其他情形外，我们将在冷静期满后完成注销，并删除或匿名化处理您的个人信息。</span>
      </div>

    </div>
    <div class="checkStatus">
      <input type="radio" value="true" v-model="checkedOption" @click="updateSelectedOption">
      <span>我已阅读并了解注销条件</span>
    </div>
    <van-form @submit="onSubmit" style="margin-left: 0.2rem;margin-top: -0.7rem">
      <van-button style="width: 94.5%;border-color: #02A7F0;background-color: #02A7F0" type="danger">下一步</van-button>
    </van-form>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      checkedOption: false
    };
  },
  methods: {
    updateSelectedOption() {
      this.checkedOption = this.checkedOption === false ? true : false
    },
    onSubmit() {
      if (this.checkedOption != 'true') {
        Toast.fail('请选择已阅读')
        return
      }
      //  同意后跳转到删除账号页面
      this.$router.push({
        path: '/delusers'
      });
    }
  },
};
</script>

<style>
.info{
  margin: 0.2rem 0.2rem;
  border: 1px solid #333;
}
.top{
  font-size: 0.2rem;
  font-weight: bold;
  top: 10%;
  text-align: center;
  border-bottom: 2px solid #333;
}
.cont{
  font-size: 0.15rem;
}

.checkStatus{
  position: relative;
  margin-top: -0.15rem;
  margin-left: 0.15rem;
  font-size: 0.15rem;
  margin-bottom: 0.05rem;
}

.common{
  font-size: 14px;
}
</style>
